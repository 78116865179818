import axios from 'axios';
import { User } from '../../base';
import { Term } from './term';

//
// command context
//

export class CommandContext {
  private api: string = '/api/console'

  private user = new User()

  constructor(private term: Term) {
    this.term = term
  }

  // exec the command from remote
  async exec(command: string, ...args: any[]) {
    let res = await axios.post(this.api, {
      command: command,
      arguments: args
    })
    for (let op of res.data.operator) {
      eval('(() => { this' + op + ' })').bind(this)()
    }
    return 'OK'
  }

  print(str: string): void {
    this.term.print(str)
  }

  println(str: string): void {
    this.term.println(str)
  }

  input(str: string): Promise<any> {
    return this.term.input(str)
  }

  password(str: string): Promise<any> {
    return this.term.password(str)
  }
}


//
// commands
//

export async function remotexec(command: string, ...args: Array<string>) {
  await this.exec(command, ...args);
}

export function echo(...args) {
  // console.log(this)
  this.print(args.join(' '))
}

export function date() {
  this.print((new Date()).toString())
}

export function exec(...args) {
  let cmd = args.join(' ')
  this.print(eval(cmd))
}

/*
export async function password() {
  let pwd = await this.password();
  this.print(`password is ${pwd}`)
}
*/

export async function login() {
  let username: string = await this.input('username: ')
  let password: string = await this.password('password: ')
  let verify: string = await this.input('verify: ')

  await this.exec('login', ...[username, password, verify])
}

export function nav(...args: Array<string>) {
  //
}
