import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'index-clock',
  template: `
    <div>
      <div class="date">{{date}}</div>
      <div class="time">{{time}}</div>
    </div>
  `,
  styles: [`
    .date { font-size: 1.05em; }
    .time { font-size: 1em; }
    * {
      text-align: center;
      display: block;
      color: #eee;
    }
  `]
})
export class ClockComponent implements OnInit {
  clock: number
  date: string
  time: string

  constructor() {
    this.clock = this.startClock()
  }

  startClock(){
    let update = () => {
      let d = new Date()
      let H = d.getHours()
      let i: any = d.getMinutes()
      let s: any = d.getSeconds()
      i = i < 10 ? '0' + i : i;
      s = s < 10 ? '0' + s : s;
      this.date = `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
      this.time = `${H}:${i}:${s}`;
    }

    update()

    return window.setInterval(update, 1000)
  }

  ngOnInit() {
  }

}
