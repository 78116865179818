import { Component, OnInit } from '@angular/core';

import { Server, User } from '../../base';
import { Article } from './model';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  private article: Article;

  constructor(
    public user: User,
    public server: Server
  ) { }

  ngOnInit() {
    this.article = new Article('hello', new Date(), '# hello world!', 'text/markdown');
  }

}
