/*
 *  Article class
 */
export class Article {

  constructor(
    public title: string,
    public createaAt: Date,
    public content: string,
    public contentType: string
  ) { }

}

export class ArticleList extends Array<Article> {

}



