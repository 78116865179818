import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment, UrlMatchResult } from '@angular/router';

import { AngularFireDatabase } from '@angular/fire/database';
import anchorme from "anchorme";

import { Server } from '../../base';
// import { ErrorComponent } from '../../index';


@Component({
  selector: 'app-card',
  template: `
    <main *ngIf="card" [innerHTML]="card.content"></main>
    <ngx-spinner *ngIf="!card"
      bdOpacity=0.9
      size="default"
      color="#fff"
      type="ball-clip-rotate">
      <p style="color: #333" > Loading... </p>
    </ngx-spinner>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  private card: any = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private server: Server,
    private db: AngularFireDatabase,
  ) { }

  ngOnInit() {
    let id = this.activatedRoute.snapshot.paramMap.get('id');
    this.loadData(id);
  }

  loadData(id) {
    this.db.object(`${id}`).snapshotChanges().subscribe(action => {
      let payload = action.payload.val();
      if (payload) {
        this.card = {
          content: anchorme(payload)
        }
      } else {
        this.router.navigate(['/error/404']);
      }
    });

  }

}
