import { Component, OnInit } from '@angular/core';
import { Server } from '../../base';



class Photo {
  constructor(
    public title: string,
    public src: string
  ) {}
}

class PhotoList {
  constructor(
    public title: string,
    public photos: Array<Photo>,
  ) {}

}

type Photos = Array<PhotoList>;


const fps = [
  new PhotoList(
    'hello',
    [
      new Photo('hello-a', '/aaaaa'),
      new Photo('hello-b', '/bbbbb'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
      new Photo('hello-c', '/ccccc'),
    ]
  )
]


@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  public photos: Photos;

  constructor(public server: Server) {
    console.log(server);

    this.photos = fps;
  }

  ngOnInit() {
    console.log('hello')
  }

}
