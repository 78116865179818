import { Component, OnInit } from '@angular/core';
// import { environment } from '../main.module';

import { AngularFireAuth } from '@angular/fire/auth';
// import { auth } from 'firebase/app';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-auth',
  template: `
    <main>
      <label>
        <input type="email" [(ngModel)]="email"
          autofocus placeholder="email"
          (keyup.enter)="onEnter()">
      </label>
    </main>
  `,
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public email = '';

  private actionCodeSettings: any = {
    url: 'https://wyf.zone/auth/action',
    // This must be true.
    handleCodeInApp: true,
    iOS: {},
    android: {},
    // dynamicLinkDomain: 'localhost'
    dynamicLinkDomain: 'https://wyf.zone/auth/action',
  }

  constructor(
    public afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
  }


  onEnter() {
    // this.login(this.username, this.password)
    this.sendLoginMail(this.email)
  }

  sendLoginMail(email: string) {
    this.afAuth.auth.sendSignInLinkToEmail(email, this.actionCodeSettings)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
    return;
    /*
    // ...
    firebase.auth().sendSignInLinkToEmail(email, this.actionCodeSettings)
      .then(function() {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      })
      .catch(function(error) {
        // Some error occurred, you can inspect the code: error.code
      });
      */
  }


  doLogin(username: string, password: string) {
    /*
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  */
  }


}
