import { Injectable } from '@angular/core';
import * as store from 'store';

import { Server } from './server';


@Injectable({
  providedIn: 'root'
})
export class User {

  private store = store.namespace('user');

  public username: string;
  public role: number;
  public token: string;
  public profile: any;

  private server: Server = new Server()

  constructor() {
    this.username = this.store.get('user.username');
    this.profile = this.store.get('user.profile');
    this.role = this.store.get('user.role');
    this.token = this.store.get('user.token');

  }

  get name() {
    return this.username;
  }

  update(username: string, role: number, token: string, profile: any) {
    this.username = username
    this.role = role
    this.token = token
    this.profile = profile

    this.store.add('user', {username, profile, role, token});
  }
}
