import { Component, OnInit } from '@angular/core';
import { ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-index',
  styleUrls: ['./index.component.scss'],
  template: `
    <div #banner class="navDisable">
      <index-clock></index-clock>
      <nav>
        <a routerLink="/about">About</a>
        <!--
        <a routerLink="/photos">Photo</a>
        <a routerLink="/articles">Articles</a>
        -->
      </nav>
    </div>
  `,
})
export class IndexComponent implements OnInit {

  @ViewChild('banner', {static: true}) banner: ElementRef;

  constructor() { }

  ngOnInit() {
    window.addEventListener('keypress', (event) => {
      console.log(event)
      if (event.key == 'Enter') {
        this.banner.nativeElement.classList.toggle('navDisable')
      }
    })
  }

}
