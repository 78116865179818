import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { ClockComponent } from './clock.component';
import { IndexComponent } from './index.component';
import { AboutComponent } from './about.component';
import { ErrorComponent } from './error.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: '**',
    component: ErrorComponent,
    data: {
      code: 404
    }
  }
]


@NgModule({
  declarations: [
    ClockComponent,
    IndexComponent,
    AboutComponent,
    ErrorComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: true }
    ),

  ]
})
export class IndexModule { }
