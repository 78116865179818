import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QrComponent } from './qr/qr.component';


const routes: Routes = [
  {
    path: 'qr',
    // matcher: QrComponent.qrUrlMacher,
    component: QrComponent,
    children: [
      {
        matcher: QrComponent.qrSubUrlMacher,
        component: QrComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToolsRoutingModule { }
