import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule }    from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { NgxSpinnerModule } from 'ngx-spinner';

import { MainRoutingModule } from './main-routing.module'

import { ArticleComponent } from './article/article.component';
import { ArticleListComponent } from './article/article-list.component';
import { AuthComponent } from './auth/auth.component';
import { CardComponent } from './card/card.component';
import { ConsoleComponent } from './console/console.component';
import { PhotoComponent } from './photo/photo.component';

import { environment } from '../../environments/environment';

// export environment;


@NgModule({
  declarations: [
    ArticleComponent,
    AuthComponent,
    CardComponent,
    ConsoleComponent,
    PhotoComponent,
    ArticleListComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    // firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    // AngularFirestoreModule,
    AngularFireDatabaseModule,
    // route
    MainRoutingModule,
    // ..
    NgxSpinnerModule
  ],
})
export class MainModule { }
