import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { IndexModule } from './index/index.module';
import { MainModule  } from './main/main.module';
import { ToolsModule } from './tools/tools.module';

@NgModule({
  imports: [
    BrowserModule,

    MainModule,
    ToolsModule,
    IndexModule,
    RouterModule,
  ],
  declarations: [
    AppComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
