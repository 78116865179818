import { Component, OnInit } from '@angular/core';
import { ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { User } from '../../base/user';

import { Term } from './term';

const termCss: string = require('xterm/dist/xterm.css');


@Component({
  selector: 'app-terminal',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `* {
      overflow: hidden !important;
    }`,
    termCss,
  ],
  template: `
    <main #terminal></main>
  `,
})

export class ConsoleComponent implements OnInit {

  @ViewChild('terminal', {static: true}) terminalDiv: ElementRef;

  private term: Term;

  constructor(
    // public user: User
  ) { }

  ngOnInit() {
    this.term = new Term(this.terminalDiv);
  }

}

