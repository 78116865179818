import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment, UrlMatchResult } from '@angular/router';


@Component({
  selector: 'app-qr',
  styles: [`
    * {
      display: block;
    }
    .error {
      color: #eee;
    }
    qr-code {
      padding: 2em;
    }
  `],
  template: `
    <main class="error" *ngIf="error">
      Error: {{error}}
    </main>

    <main *ngIf="!error">

      <qr-code
        value="{{value}}"
        size="280"
        errorCorrectionLevel="M">
      </qr-code>

    </main>
  `
})
export class QrComponent implements OnInit {

  error: string = '';

  value: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    /**
     * /qr/of/123456 -> origin text
     * /qr/b64/aGVsbG8gd29ybGQh -> base64 encoded text
     *
     * oh fuck
     */
    let prefix = this.route.snapshot.children[0].params['prefix']
    let content = this.route.snapshot.children[0].params['content']

    switch (prefix) {
      case 'for': {
        this.value = content;
        break;
      }

      case 'b64': {
        try {
          this.value = window.atob(content);
        } catch (error) {
          this.error = `can not decede "${content}"`
        }
        break;
      }

      default: {
        this.error = `unknown prefix "${prefix}"`
        break;
      }
    }
  }

  /**
   * match (?P<prefix>[^/]+)/(P?<content>.*)
   *
   * oh fuck
   */
  static qrSubUrlMacher(url: UrlSegment[]) {
    if (url.length < 2 ) return null;

    let prefix = url[0];

    // re-join path
    let contentPath = url.slice(1).map(u => u.path).join('/');
    let content = new UrlSegment(contentPath, {})

    const posParams: {[key: string]: UrlSegment} = {};

    // posParams['prefix'] = prefix;
    // posParams['content'] = content;

    return {
      consumed: url,
      posParams: {
        prefix,
        content
      }
    }

  }

}
