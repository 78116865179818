import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QrCodeModule } from 'ng-qrcode';

import { QrComponent } from './qr/qr.component';
import { ToolsRoutingModule } from './tools-routing.module';


@NgModule({
  declarations: [
    QrComponent
  ],
  imports: [
    CommonModule,
    QrCodeModule,
    ToolsRoutingModule
  ]
})
export class ToolsModule { }
