import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-error',
  styles: [`
    main {
      font-size: 2em;
      color: #ccc;
    }
    :host {
      font-size: 1.5rem;
      display: flex;
      padding-bottom: 20rem;
      align-items: center;
    }
  `],
  template: `
    <main>Error: {{code}}</main>
  `,
})
export class ErrorComponent implements OnInit {

  code: number = 400

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { code: number }) => {
        this.code = data.code
      })
  }

}
